.ru-dragbar {
  display: flex;
  .ru-dargbar-extra {
    background-color: rgba(255, 255, 255, 0.25);
    height: 8px;
    align-self: center;
    border-radius: 8px 0 0 8px;
  }
  .dragbar-slider {
    flex: 1;
    height: 20px;
    .dragbar-thumb {
      width: 24px;
      height: 20px;
      display: flex;
      background-color: #fff;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.32);
      border-radius: 4px;
      padding: 4px 8px;
      outline: none;
      cursor: pointer;
      & > div {
        width: 2px;
        height: 100%;
        background-color: #292a33;
        border-radius: 16px;
        &:first-child {
          margin-right: 4px;
        }
      }
    }
    .dragbar-track {
      top: 6px;
      height: 8px;
      border-radius: 8px;
    }
    .dragbar-track-0 {
      background-color: #fff;
    }
    .dragbar-track-1 {
      background-color: #292a33;
    }
    &.more-than-0 {
      .dragbar-track-0 {
        border-radius: 0 8px 8px 0;
      }
    }
  }
}
